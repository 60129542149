import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// Routes
import admin from './routes/admin'
import website from './routes/website'
import client from './routes/client'
import Bus from "../event-bus";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...website,
    ...admin,
    ...client,
    
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    localStorage.setItem('routeCFCheck','no')
    next('/login');
  }else if(store.getters.isAuthenticated && to.meta.requiresAuth && to.meta.accountStatus == 'approved' && store.getters.currentUser.account_status == 'incomplete'){
    localStorage.setItem('routeCFCheck','no')
    next('/change-account-status');
  }else if(store.getters.isAuthenticated && !to.meta.requiresAuth && store.getters.currentUser.role == 'super_admin' ){
    localStorage.setItem('routeCFCheck','no')
    next('/admin/dashboard');
  }else if(store.getters.isAuthenticated && !to.meta.requiresAuth && (store.getters.currentUser.role !='super_admin')){
    localStorage.setItem('routeCFCheck','no')
    next('/client/dashboard');
  }else if(store.getters.isAuthenticated && (store.getters.currentUser.role =='super_admin' && to.meta.showFor == 'client')){
    localStorage.setItem('routeCFCheck','no')
    next('/admin/dashboard');
  }else if(store.getters.isAuthenticated && (store.getters.currentUser.role !='super_admin' && to.meta.showFor == 'administrator')){
    localStorage.setItem('routeCFCheck','no')
    next('/client/dashboard');
  }else{

    if (localStorage.getItem('routeCFCheck') == 'yes') {
        
        Swal.fire({
          title: 'Please Confirm',
          text: "Do you want to Go back without saving your report?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
            if(result.isConfirmed == true){
              localStorage.setItem('routeCFCheck','no');

              //Bus.$emit('update-profile');

              if(to.name != 'analytics'){
                
                localStorage.setItem('refresh_analytics','false');
              }
              
              Bus.$emit('checkuser');



              return next();
            }
        })
        
    }else{
        //Bus.$emit('update-profile');
        localStorage.setItem('routeCFCheck','no')
        
        if(to.name != 'analytics'){
          
          localStorage.setItem('refresh_analytics','false');
        }

        Bus.$emit('checkuser');

        return next();
      
    }    
    
  }  
})

export default router
